import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"
import { GatsbySeo } from 'gatsby-plugin-next-seo';


export default function Homepage(props) {
  const { homepage } = props.data

  return (
    <>
      <GatsbySeo
                  title='Michael Law | Whanganui Council 2022'
                  description='Whanganui District Councillor; Michael Law has a three term strategy for a greater Whanganui.'
                />
    <Layout {...homepage}>
        
      {homepage.blocks.map((block) => {
        const { id, blocktype, ...componentProps } = block
        const Component = sections[blocktype] || Fallback
        return <Component key={id} {...componentProps} />
      })}
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    homepage {
      id
      title
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...HomepageHeroContent
        ...HomepageFeatureListContent
        ...HomepageCtaContent
        ...HomepageLogoListContent
        ...HomepageTestimonialListContent
        ...HomepageBenefitListContent
        ...HomepageStatListContent
        ...HomepageProductListContent
      }
    }
  }
`
